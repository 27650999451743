import { Injectable, signal } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoadingBarService {
    readonly isLoading = signal(false);

    constructor(private router: Router) {
        this.setupNavigationListener();
    }

    private setupNavigationListener(): void {
        this.router.events
            .pipe(
                filter(event =>
                    event instanceof NavigationStart ||
                    event instanceof NavigationEnd ||
                    event instanceof NavigationCancel ||
                    event instanceof NavigationError
                )
            )
            .subscribe(event => {
                if (event instanceof NavigationStart) {
                    this.setLoading(true);
                } else {
                    // NavigationEnd, NavigationCancel, NavigationError
                    this.setLoading(false);
                }
            });
    }

    public setLoading(isLoading: boolean): void {
        this.isLoading.set(isLoading);
    }
}